<template>
  <v-container fluid class="grey lighten-5">
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="4">
        <v-card
          class="fill-height d-flex flex-column"
          outlined
        >
          <v-card-text>
            <v-form ref="form">
              <v-container fluid pa-0>
                <v-row>
                  <v-col cols="12" sm="12" md="4" lg="12">
                    <v-autocomplete
                      v-model="rede"
                      :items="redes"
                      label="Rede"
                      @change="filter()"
                      clearable flat hide-details
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="4" lg="12">
                    <v-autocomplete
                      v-model="municipio"
                      :items="municipios"
                      label="Município"
                      @change="filter()"
                      clearable flat hide-details
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="4" lg="12">
                    <v-autocomplete
                      v-model="area"
                      :items="areas"
                      label="Área"
                      @change="filter()"
                      clearable flat hide-details
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="12">
                    <v-autocomplete
                      v-model="instituicao"
                      :items="instituicoes"
                      label="Instituição"
                      @change="filter()"
                      :disabled="!area"
                      clearable flat hide-details
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" lg="12">
                    <v-autocomplete
                      v-model="unidade"
                      :items="unidades"
                      label="Unidade"
                      @change="filter()"
                      :disabled="!area"
                      clearable flat hide-details
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-spacer />
          <v-card-actions>
            <v-btn @click="exportCsv">
              <v-icon left>
                mdi-download
              </v-icon>
              Baixar Planilha
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn @click="clear" width="125">
              Limpar
            </v-btn>
            <!--
            <v-btn
              :loading="loading"
              color="green lighten-1" dark
              @click="addLayer()"
              width="125"
            >
              Pesquisar
            </v-btn>
            -->
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="12" lg="8"><!-- class="pa-0" -->
        <v-card outlined :loading="loading">
          <div id="map" style="height: 490px; z-index: 0;"></div>
        </v-card>
      </v-col>
    </v-row>
    <!--
    <v-row>
      <v-col cols="12" lg="6">
        <v-card
          class="fill-height d-flex flex-column pb-2"
          outlined
        >
          <canvas id="myChart" style="height: 220px; max-height: 220px"></canvas>
        </v-card>
      </v-col>
      <v-col cols="12" lg="3">
        <v-card
          class="fill-height d-flex flex-column pb-2"
          outlined
        >
          <canvas id="myChart2" style="height: 220px; max-height: 220px"></canvas>
        </v-card>
      </v-col>
      <v-col cols="12" lg="3">
        <v-card
          class="fill-height d-flex flex-column pb-2"
          outlined
        >
          <div id="legend-2" class="chart-legend"></div>
        </v-card>
      </v-col>
    </v-row>
    -->
    <v-row>
      <v-col cols="12">
        <v-card outlined>
          <v-card-title>
            Rede de Proteção à Criança e ao Adolescente
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pesquisar"
              clearable
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="items"
            :search="search"
            :loading="loading"
          >
            <template v-slot:item.unidade="{ item }">
              <b style="color: #1B5E20;">{{ item.unidade }}</b>
            </template>
            <template v-slot:item.endereco="{ item }">
              {{ enderecoCompleto(item) }}
              <!--
              <span v-if="item.endereco">{{ item.endereco }}</span>
              <span v-if="item.numero">, {{ item.numero }}</span>
              <span v-if="item.complemento">, {{ item.complemento }}</span>
              <span v-if="item.bairro">, {{ item.bairro }}</span>
              <span v-if="item.cep">, {{ item.cep }}</span>
              -->
            </template>
            <template v-slot:item.telefone="{ item }">
              <span v-if="item.telefone">
                {{ item.telefone }}
              </span>
            </template>
            <template v-slot:item.acoes="{ item }">
              <v-btn @click="zoomTo(item)"
                v-if="item.latitude && item.longitude"
                icon
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
              <!--
              <v-btn icon v-if="item.website" :href="item.website">
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
              -->
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
// import Chart from 'chart.js'
import * as L from 'leaflet'
import { basemapLayer } from 'esri-leaflet'
import { isNil, isString, toString } from 'lodash'
// import { groupBy } from 'lodash'
import XLSX from 'xlsx'

/*
Para corrigir o problema do separador do milhar: -50258 -> -50.258

UPDATE seds.tb_unidade
SET longitude = longitude / 1000
WHERE longitude < -100

UPDATE seds.tb_unidade
SET latitude = latitude / 1000
WHERE latitude < -100
*/

export default {
  name: 'Fortis',
  data: () => ({
    items: [],
    loading: false,
    search: null,
    headers: [
      { text: 'Rede', value: 'rede', width: '5%' },
      // { text: 'Município', value: 'municipio' },
      { text: 'Área', value: 'area', width: '10%' },
      { text: 'Instituição', value: 'instituicao', width: '15%' },
      { text: 'Unidade', value: 'unidade', width: '20%' },
      { text: 'Endereço', value: 'endereco', width: '20%' },
      { text: 'Telefone', value: 'telefone', width: '10%' },
      { text: 'E-mail', value: 'email', width: '15%'},
      // { text: 'Site', value: 'website', width: '5%' },
      { text: '', value: 'acoes', width: '5%' }
    ],

    rede: null,
    municipio: null,
    area: null,
    instituicao: null,
    unidade: null,

    redes: [],
    municipios: [],
    areas: [],
    instituicoes: [],
    unidades: []
  }),
  mounted () {
    this.items = []

    this.redes = ["Estadual", "Municipal"];

    // IFS=","; while read before after; do sed -i "s/$before/$after/g" FORTIS.js; done < replacements.csv

    this.$nextTick(() => {
      this.map = L.map('map', {
        // scrollWheelZoom: false
      })

      var baseLayers = {
        'ESRI Gray': basemapLayer('Gray'),
        'ESRI Imagery': basemapLayer('Imagery'),
        'ESRI Topographic': basemapLayer('Topographic').addTo(this.map),
        'OpenStreetMap': L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        })
      }

      var overlays = {
        'Municípios do Paraná': L.tileLayer.wms('http://geoserver.pr.gov.br/geoserver/ows', {
          layers: 'itcg:municipios_pol_p31982_e50',
          format: 'image/png',
          transparent: true
        })
      }

      L.control.layers(baseLayers, overlays).addTo(this.map)

      this.defaultExtent = L.control.defaultExtent()
        .addTo(this.map)

      L.control.fullscreen({
        title: {
          'false': 'Ver em tela inteira',
          'true': 'Sair da tela inteira'
        }
      }).addTo(this.map)

      this.markers = L.markerClusterGroup({
        chunkedLoading: true
      })

      this.map.addLayer(this.markers)

      // this.addChart()
      // this.addChart2()
      this.filter()
    })
  },
  methods: {
    addLayer () {
      this.filter()
    },
    removeLayer () {
      this.markers.clearLayers()
    },
    clear () {
      this.removeLayer()
      this.$refs.form.reset()
      this.filter()
    },
    exportCsv () {
      var ws = XLSX.utils.json_to_sheet(this.items)
      // var wb = XLSX.utils.table_to_book(sheet, {sheet:"Sheet JS"});

      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'SheetJS');

      XLSX.writeFile(wb, 'RedeProtecao.xlsx')
    },
    /*
    addChart () {
      var ctx = document.getElementById('myChart').getContext('2d')

      this.chart = new Chart(ctx, {
        type: 'doughnut',
        data: {
          labels: [],
          datasets: [{
            label: '# de Unidades',
            data: [],
            backgroundColor: [
              'rgb(255, 99, 132)',
              'rgb(255, 159, 64)',
              'rgb(255, 205, 86)',
              'rgb(75, 192, 192)',
              'rgb(54, 162, 235)',
              'rgb(153, 102, 255)',
              'rgb(201, 203, 207)'
            ],
            hoverOffset: 4
          }]
        },
        options: {
          title: {
            display: true,
            text: 'Unidade x Área'
          },
          legend: {
            position: 'right'
          }
        }
      })
    },
    addChart2 () {
      var ctx = document.getElementById('myChart2').getContext('2d')

      this.chart2 = new Chart(ctx, {
        type: 'doughnut',
        data: {
          labels: [],
          datasets: [{
            data: [],
            backgroundColor: [
              'rgb(255, 99, 132)',
              'rgb(255, 159, 64)',
              'rgb(255, 205, 86)',
              'rgb(75, 192, 192)',
              'rgb(54, 162, 235)',
              'rgb(153, 102, 255)',
              'rgb(201, 203, 207)'
            ],
            hoverOffset: 4
          }]
        },
        options: {
          title: {
            display: true,
            text: 'Unidade x Instituição'
          },
          legend: {
            display: false
          }
        }
      })

      document.getElementById('legend-2').innerHTML = this.chart2.generateLegend();
    },
    updateChart (items) {
      var data = groupBy(items, 'area')
      this.labels = Object.keys(data)
      this.data = []
      for (var i = 0; i < this.labels.length; i++) {
        var label = this.labels[i]
        this.data.push(data[label].length)
      }

      this.chart.data.labels = this.labels
      this.chart.data.datasets[0].data = this.data
      this.chart.update()
    },
    updateChart2 (items) {
      var data = groupBy(items, 'instituicao')

      this.labels = Object.keys(data)
      this.data = []
      for (var i = 0; i < this.labels.length; i++) {
        var label = this.labels[i]
        this.data.push(data[label].length)
      }

      this.chart2.data.labels = this.labels
      this.chart2.data.datasets[0].data = this.data
      this.chart2.update()

      document.getElementById('legend-2').innerHTML = this.chart2.generateLegend();
    },
    */
    filter () {
      this.removeLayer()

      this.items = []
      this.loading = true

      var params = {
        rede: this.rede,
        municipio: this.municipio,
        area: this.area,
        instituicao: this.instituicao,
        unidade: this.unidade
      }

      axios.get(`${process.env.VUE_APP_ROOT_API}/unidades`, {
        params: params,
        paramsSerializer: params => qs.stringify(params, {
          arrayFormat: 'repeat',
          skipNulls: true
        })
      })
        .then(response => {
          this.items = response.data
          this.refreshMap()
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.loading = false
        })

      // this.refreshMap()
      // this.updateChart(this.items)
      // this.updateChart2(this.items)
    },
    enderecoCompleto (item) {
      var endereco = ''
      if (item.logradouro) {
        endereco += item.logradouro
      }
      if (item.numero) {
        endereco += ', ' + item.numero
      }
      if (item.complemento) {
        endereco += ', ' + item.complemento
      }
      if (item.bairro) {
        endereco += ', ' + item.bairro
      }
      if (item.municipio) {
        endereco += ', ' + item.municipio
      }
      if (item.cep) {
        endereco += ', ' + toString(item.cep).replace(/^(\d{5})(\d{3}).*/, '$1-$2');
      }
      return endereco
    },
    zoomTo (item) {
      // window.scrollTo(0, document.body.scrollHeight)
      window.scrollTo(0, 0)
      this.map.setView([item.latitude, item.longitude], 16)
    },
    refreshMap () {
      function createIcon (iconUrl) {
        return new L.Icon({
          iconUrl: iconUrl,
          iconSize: [32, 37],
          popupAnchor: [0, -8]
        })
      }

      var hospital = createIcon(require('@/assets/icon/hospital.png'))
      var theater = createIcon(require('@/assets/icon/theater.png'))
      var communitycentre = createIcon(require('@/assets/icon/communitycentre.png'))
      var library = createIcon(require('@/assets/icon/library.png'))
      var university = createIcon(require('@/assets/icon/university.png'))
      var daycare = createIcon(require('@/assets/icon/daycare.png'))
      var retirement_home = createIcon(require('@/assets/icon/retirement_home.png'))
      var police = createIcon(require('@/assets/icon/police.png'))
      var court = createIcon(require('@/assets/icon/court.png'))
      var disability = createIcon(require('@/assets/icon/disability.png'))
      var female = createIcon(require('@/assets/icon/female-2.png'))
      var workcase = createIcon(require('@/assets/icon/workcase.png'))

      var icons = {
        "Arte e Cultura": theater,
        "Assistência Social": communitycentre,
        "Direitos Consumidor": court,
        "Direitos Humanos": court,
        "Conselho Tutelar": library,
        "Conselho de Direitos": library,
        "Criança": daycare,
        "Criança e Adolescente": daycare,
        "Educação": university,
        "Formação/Capacitação": library,
        "Idoso": retirement_home,
        "Justiça": court,
        "Justiça/Tráfico de Pessoas": court,
        "Juventude": communitycentre,
        "Mulher": female,
        "Pessoa com Deficiência": disability,
        "Regionais": communitycentre,
        "Saúde": hospital,
        "Segurança Pública": police,
        "Sistema de Justiça": court,
        "Social": court,
        "Socioeducação": court,
        "Trabalho": workcase
      }

      var municipios = new Set()
      var areas = new Set()
      var instituicoes = new Set()
      var unidades = new Set()

      for (var i = 0; i < this.items.length; i++) {

        if (!this.municipio && this.items[i].municipio) {
          municipios.add(this.items[i].municipio)
        }
        if (!this.area && this.items[i].area) {
          areas.add(this.items[i].area)
        }
        if (this.area) {
          if (!this.instituicao && this.items[i].instituicao) {
            instituicoes.add(this.items[i].instituicao)
          }
          if (!this.unidade && this.items[i].unidade) {
            unidades.add(this.items[i].unidade)
          }
        }

        var lat = this.items[i].latitude
        var lon = this.items[i].longitude
        if (lat === -90) {
          lat = null
          lon = null
        }
        // if (isEmpty(lon)) lon = null
        if (lat && lon) {
          if (isString(lat)) {
            lat = parseFloat(lat.replace(',', '.'))
          }
          if (isString(lon)) {
            lon = parseFloat(lon.replace(',', '.'))
          }

          lat = L.Util.formatNum(lat, 6)
          lon = L.Util.formatNum(lon, 6)

          var icon = icons[this.items[i].area]
          /*
          switch (this.items[i].area) {
            case "Arte e Cultura":
              icon = theater
              break;
            case "Assistência Social":
              icon = communitycentre
              break;
            case "Direitos Consumidor":
            case "Direitos Humanos":
              icon = court
              break;
            case "Conselho Tutelar":
            case "Conselho de Direitos":
              icon = library
              break;
            case "Criança":
            case "Criança e Adolescente":
              icon = daycare
              break;
            case "Educação":
              icon = university
              break;
            case "Formação/Capacitação":
              icon = library
              break;
            case "Idoso":
              icon = retirement_home
              break;
            case "Justiça":
            case "Justiça/Tráfico de Pessoas":
              icon = court
              break;
            case "Juventude":
              icon = communitycentre
              break
            case "Mulher":
              icon = female
              break;
            case "Pessoa com Deficiência":
              icon = disability
              break;
            case "Regionais":
              icon = communitycentre
              break;
            case "Saúde":
              icon = hospital
              break;
            case "Segurança Pública":
              icon = police
              break;
            case "Sistema de Justiça":
              icon = court
              break;
            case "Social":
            case "Socioeducação":
              icon = court
              break;
            case "Trabalho":
              icon = workcase
              break;
          }
          */
          var marker = null
          if (icon) {
            marker = L.marker([lat, lon], {
              icon: icon
            })
          } else {
            marker = L.marker([lat, lon])
          }

          var template = ''

          if (this.items[i].unidade) template += '<h2>{unidade}</h2>'
          if (this.items[i].rede) template += '<b>Rede:</b> {rede}<br>'
          if (this.items[i].area) template += '<b>Área:</b> {area}<br>'
          if (this.items[i].instituicao) template += '<b>Instituição:</b> {instituicao}<br>'
          if (this.items[i].logradouro) template += '<b>Logradouro:</b> {logradouro}<br>'
          if (this.items[i].numero) template += '<b>Número:</b> {numero}<br>'
          if (this.items[i].complemento) template += '<b>Complemento:</b> {complemento}<br>'
          if (this.items[i].bairro) template += '<b>Bairro:</b> {bairro}<br>'
          if (this.items[i].cep) template += '<b>CEP:</b> {cep}<br>'
          if (this.items[i].municipio)  template += '<b>Município:</b> {municipio}<br>'

          if (this.items[i].telefone) template += '<b>Telefone:</b> {telefone}<br>'
          if (this.items[i].email) template += '<b>E-mail:</b> {email}<br>'
          if (this.items[i].site) template += '<b>Site:</b> {site}<br>'
          if (this.items[i].horario) template += '<b>Horário de Atendimento:</b> {horario}<br>'
          if (this.items[i].obs) template += '<b>Observação:</b> {obs}<br>'
          // if (this.items[i].longitude) template += '<b>Longitude:</b> {longitude}<br>'
          // if (this.items[i].latitude) template += '<b>Latitude:</b> {latitude}<br>'

          marker.bindPopup(L.Util.template(template, this.items[i]))

          this.markers.addLayer(marker)
        }
      }
      if (!this.municipio) {
        this.municipios = Array.from(municipios).sort()
      }
      if (!this.area) {
        this.areas = Array.from(areas).sort()
      }
      if (!this.instituicao) {
        this.instituicoes = Array.from(instituicoes).sort()
      }
      if (!this.unidade) {
        this.unidades = Array.from(unidades).sort()
      }

      // initialize bounds at the first search
      if (isNil(this.bounds)) {
        this.bounds = this.markers.getBounds()
      }

      this.map.once("moveend", () => {
        this.defaultExtent.setCenter(this.map.getCenter())
        this.defaultExtent.setZoom(this.map.getZoom())
      })

      if (this.markers.getBounds().isValid()) {
        this.map.fitBounds(this.markers.getBounds())
      } else {
        this.map.fitBounds(this.bounds)
      }
    }
  }
}
</script>

<style>
.chart-legend li span {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 5px;
}
.chart-legend li {
  margin-top: 3px;
  margin-bottom: 3px;
}
.chart-legend {
  overflow: auto;
  height: 220px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif !important;
  font-size: 12px !important;
}
</style>
