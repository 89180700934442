<template>
  <v-app>
    <v-app-bar height="85" max-height="85">
      <img src="@/assets/logo-infanciasegura-250x110.png" height="75" max-height="75" ><!-- height="56" -->
      <!--
      <v-toolbar-title>Redes de Proteção</v-toolbar-title>
      -->
      <v-spacer />
      <img src="@/assets/logo_parana_225x66.png" >
    </v-app-bar>
    <v-divider />
    <v-main>
      <Fortis />
    </v-main>
  </v-app>
</template>

<script>
import Fortis from './components/Fortis';

export default {
  name: 'App',

  components: {
    Fortis,
  },

  data: () => ({
    //
  }),
};
</script>
